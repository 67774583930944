import React from 'react'
import PropTypes from 'prop-types'

const Gallery = props => {
  return (
    <div>Gallery</div>
  )
}

Gallery.propTypes = {}

export default Gallery